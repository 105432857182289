<template>
    <!-- begin register -->
    <div class="register register-with-news-feed">
        <!-- begin news-feed -->
        <div class="news-feed bg-teal-transparent-2">
            <div class="text-center pb-3" style="margin-top: 160px;">
                <img :src="logoSumut" width="200" alt="logoSumut" />
            </div>
            <div>
                <h3 class="d-flex justify-content-center">
                    PEMERINTAH PROVINSI SUMATERA UTARA
                </h3>
                <h3 class="d-flex justify-content-center">
                    BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH
                </h3>
                <p
                    class="font-weight-bold d-flex justify-content-center"
                    style="font-size: 15px"
                >
                    Jl. Sisingamangaraja Km 5,5 Medan
                </p>
            </div>
        </div>
        <!-- end news-feed -->
        <!-- begin right-content -->
        <div class="right-content">
            <!-- begin register-header -->
            <h1 class="register-header">
                Kirim Ulang Email Verifikasi
                <small
                    >Silahkan isi dengan email yang telah anda daftarkan sebelumnya.</small
                >
            </h1>
            <!-- end register-header -->
            <!-- begin register-content -->
            <div class="register-content">
                <form
                    class="margin-bottom-0"
                    @submit.prevent="register"
                    @keydown="form.onKeydown($event)"
                    autocomplete="off"
                >
                    <alert-error
                        :form="form"
                        class="border-danger mb-2"
                    ></alert-error>
                    <label class="control-label"
                        >Email
                        <span class="text-danger">*</span></label
                    >
                    <div class="row m-b-15">
                        <div class="col-md-12">
                            <input
                                type="email"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': form.errors.has('email'),
                                }"
                                v-model="form.email"
                                placeholder="Masukkan Email"
                                :disabled="isDisable"
                            />
                            <em
                                v-if="form.errors.has('email')"
                                class="form-text text-danger"
                                >{{ errors.email[0] }}</em
                            >
                        </div>
                    </div>
                    <div class="register-buttons">
                        <b-button variant="success" block type="submit" :disabled="isDisable"> 
                            <span v-if="isLoading"
                                ><b-spinner
                                    variant="light"
                                    small
                                    label="loading"
                                ></b-spinner
                            ></span>
                            Kirim Ulang
                        </b-button>
                    </div>
                    <div class="m-t-20 p-b-40 text-inverse">
                        <div>
                            Sudah punya akun?
                        </div>
                        <router-link to="/auth/login" class="h6">Ke Halaman Login</router-link> atau <router-link class="h6" to="/registrasi/wajib-retribusi">Ke Halaman Registrasi</router-link>
                    </div>
                    <hr />
                    <p class="text-center">
                        &copy; Pemerintah Provinsi Sumatera Utara | All Right
                        Reserved 2020
                    </p>
                </form>
            </div>
            <!-- end register-content -->
        </div>
        <!-- end right-content -->
    </div>
    <!-- end register -->
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import PageOptions from "@/config/PageOptions.vue";
import Logo from "@/assets/images/logoSumut.png";

export default {
    data() {
        return {
            logoSumut: Logo,
            form: new Form({
                email: "",
            }),
            errors: [],
            isLoading: false,
            isDisable: false
        };
    },
    created() {
        PageOptions.pageEmpty = true;
        document.body.className = "bg-white";
    },
    beforeRouteLeave(to, from, next) {
        PageOptions.pageEmpty = false;
        document.body.className = "";
        next();
    },
    methods: {
        register() {
            this.isLoading = true
            this.isDisable = true
            this.form
                .post("/api/email/resend")
                .then((response) => {
                    if (this.form.successful) {
                        this.form.reset();
                        this.$swal({
                            icon: "success",
                            title: "Proses Berhasil",
                            html:
                                "Permintaan anda berhasil diproses <br> Silahkan cek email anda untuk melanjutkan proses verifikasi.",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            showCancelButton: true,
                            cancelButtonClass: "btn btn-danger",
                            cancelButtonText: "Tutup",
                            confirmButtonText: "Ke Halaman Login",
                            showCloseButton: true,
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$router.push({ name: "Login" });
                            }
                        });
                        this.isLoading = false
                        this.isDisable = false
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.isDisable = false
                    if (error.response.status == 422) {
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else {
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            text:"ERROR: " + error.response.data.message,
                            confirmButtonText: "OKE",
                            showConfirmButton: true,
                            confirmButtonClass: "btn btn-primary",
                            timer: 5000
                        })
                    }
                });
        },
    },
};
</script>
